.Container {
  position: fixed;
  z-index: 1001;
}

.Content {
  display: flex;
  align-items: center;
}

.Container svg {
  font-size: 2.5rem;
  margin: 0 1rem 0 0;
}

.TopRight {
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: notification-right 0.6s;
}

.BottomRight {
  bottom: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: notification-right 0.6s;
}

.TopLeft {
  top: 12px;
  left: 12px;
  transition: transform 0.6s ease-in-out;
  animation: notification-left 0.6s;
}

.BottomLeft {
  bottom: 12px;
  left: 12px;
  transition: transform 0.6s ease-in-out;
  animation: notification-left 0.6s;
}

.Notification {
  background: #ccc;
  color: white;
  position: relative;
  overflow: hidden;
  width: 320px;
  max-height: 150px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.CloseButton {
  position: absolute;
  color: white;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  cursor: pointer;
  background-color: transparent;
  font-size: 2rem;
  font-weight: 500;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: none;
  outline: none;
}

.CloseButton:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.Title {
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0.2rem;
}

.Message {
  margin: 0.2rem;
  overflow: hidden;
  white-space: normal;
}

.Success {
  background-color: rgb(63, 194, 134);
}

.Danger {
  background-color: rgb(230, 70, 70);
}

.Warning {
  background-color: rgb(238, 173, 20);
}

.Info {
  background-color: rgb(56, 98, 224);
}

@keyframes notification-right {
  from {
    transform: translateX(110%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes notification-left {
  from {
    transform: translateX(-110%);
  }
  to {
    transform: translateX(0);
  }
}
