.wrapper {
  display: flex;
  align-items: center;
}

.keypad-buttons-1-grid {
  display: grid;
  grid-template-columns: repeat(3, 4.5rem);
  grid-template-rows: repeat(2, 2.5rem);
  grid-gap: 0.1rem;
  margin-right: 1rem;
}

.keypad-buttons-2-grid {
  display: grid;
  grid-template-columns: repeat(4, 4.5rem);
  grid-template-rows: repeat(4, 2.5rem);
  grid-gap: 0.1rem;
}

@media (max-width: 600px) {
  .keypad-buttons-1-grid {
    grid-template-columns: repeat(3, 3rem);
    grid-template-rows: repeat(2, 2rem);
  }

  .keypad-buttons-2-grid {
    grid-template-columns: repeat(4, 3rem);
    grid-template-rows: repeat(4, 2rem);
  }
}
