.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0.5rem;
}

.WatchContainer {
  position: relative;
  width: 40vw;
  height: 40vw;
}

.SmartWatch {
  width: 100%;
  height: 100%;
}

.SmartWatchScale {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
}

@media (max-width: 1000px) {
  .WatchContainer {
    width: 90vw;
    height: 90vw;
  }
}
