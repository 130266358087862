.wrapper {
  width: 60vw;
  height: 40vw;
  position: relative;
  margin: 0 0.5rem;
  overflow: hidden;
}

.football-field,
.lines {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.lines {
  z-index: 30;
}

@media (max-width: 1000px) {
  .wrapper {
    width: 90vw;
    height: 60vw;
    margin-bottom: 1rem;
  }
}
