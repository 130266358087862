.Toolbar {
    height: 50px;
    width: 100%;
    position: fixed;
    top: 0;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(78, 75, 71) 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    box-sizing: border-box;
    z-index: 1000;
}
