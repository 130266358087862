.wrapper {
  display: flex;
  align-items: center;
}

.wrapper:not(:last-child) {
  margin-bottom: 0.7rem;
}

.wrapper label {
  cursor: pointer;
  display: flex;
  line-height: 1.4;
  font-size: 1.05rem;
}

.wrapper input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.wrapper label::before {
  content: "";
  width: 1.3rem;
  height: 1.3rem;
  border: 0.05rem solid #aaa;
  border-radius: 0.2rem;
  margin-right: 0.5rem;
}

.wrapper input:disabled,
.wrapper input:disabled + label::before {
  background-color: #ddd;
  cursor: not-allowed;
}

.wrapper input:disabled + label {
  cursor: not-allowed;
}

.wrapper input:checked + label::before {
  content: "\002714";
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.2rem;
}

.wrapper input:checked:disabled + label::before {
  background-color: var(--color-secondary);
}
