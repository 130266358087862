.SNA12 {
  display: flex;
  width: calc(100vw - 40px);
  height: calc(100vh - 90px);
  position: fixed;
  top: 70px;
  right: 20px;
  left: 20px;
}

.Scale {
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 100;
}

@media (max-width: 1000px) {
  .SNA12 {
    display: block;
  }
  .Scale {
    right: 20px;
    top: 20px;
    left: auto;
  }
}
