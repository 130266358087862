.wrapper {
  position: absolute;
  width: 140vw;
  height: 15vw;
  top: -10vw;
  transform-style: preserve-3d;
  transform: translateY(13vw) translateZ(5.75vw);
}

.box {
  position: absolute;
  width: 11vw;
  height: 11vw;
  left: calc(1vw * var(--cubePosition));
  transform-style: preserve-3d;
}

.side {
  position: absolute;
  width: inherit;
  height: inherit;
  background: #c1785c;
  border: 1px solid #a35739;
  text-align: center;
  line-height: 5.5vw;
  font-size: 2vw;
  font-weight: bold;
  box-sizing: border-box;
}

.front {
  transform: translateZ(-0.5vw);
  background: black;
  border: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.back {
  transform: rotateY(180deg) translateZ(5.5vw);
}

.right {
  width: 50%;
  transform: rotateY(90deg) translateZ(8.25vw) translateX(2.75vw);
}

.left {
  width: 50%;
  transform: rotateY(-90deg) translateZ(2.75vw) translateX(-2.75vw);
}

.top {
  height: 50%;
  transform: rotateX(90deg) translateZ(2.75vw) translateY(-2.75vw);
}

.bottom {
  height: 50%;
  transform: rotateX(-90deg) translateZ(8.25vw) translateY(2.75vw);
}

.plant {
  width: calc(1vw * var(--size));
  height: calc(1vw * var(--size));
  margin: auto;
  border-radius: 30%;
  background: #00b300;
}
