.wrapper {
  position: fixed;
  width: 100%;
  height: 12rem;
  bottom: 0;
  left: 0;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  transition: transform 0.2s ease-out;
}

.keypad-btn {
  position: fixed;
  background-color: #eee;
  border: 1px solid #ccc;
  padding: 0.5rem 1.5rem;
  bottom: 12rem;
  left: 0;
  cursor: pointer;
}

.keypad-btn svg {
  width: 1.7rem;
  height: 1.7rem;
}

.keypad-btn svg:first-child {
  margin-right: 0.5rem;
}

.open svg:last-child {
  transform: rotate(180deg);
}

.open {
  transform: translateY(0);
}

.close {
  transform: translateY(100%);
}

@media (max-width: 600px) {
  .wrapper {
    height: 9rem;
  }

  .keypad-btn {
    bottom: 9rem;
    padding: 0.3rem 1rem;
  }

  .keypad-btn svg {
    width: 1.2rem;
    height: 1.2rem;
  }
}
