.footer {
  padding: 1rem;
  margin-top: 1.5rem;
  background-color: #242424;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sponsor {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logos {
  display: flex;
}

.flag,
.edTech,
.description {
  margin: 0.3rem 0.5rem;
}

.flag {
  background-image: url(../../assets/sponsor/flag_yellow_low.jpg);
  background-size: cover;
  width: 60px;
  height: 40px;
}

.edTech {
  background-image: url(../../assets/sponsor/IMPACT_EdTech_logo_white.png);
  background-size: cover;
  width: 83px;
  height: 40px;
}

.description {
  color: white;
  width: 45%;
  font-size: 11px;
  font-weight: 600;
}

.copyright {
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.logo-box {
  text-align: center;
  margin-bottom: 1rem;
}

.logo {
  width: 12rem;
  height: auto;
}

/* Large smartphone */
@media (max-width: 823px) {
  .description {
    width: 55%;
    font-size: 9px;
  }

  .logo {
    width: 10rem;
  }
}

/* Small smartphone */
@media (max-width: 375px) {
  .sponsor {
    flex-direction: column;
  }

  .description {
    width: 80%;
  }

  .logo {
    width: 8rem;
  }
}
