.wrapper {
  --wrapper-width: 60vw;
  --wrapper-height: 40vw;

  width: var(--wrapper-width);
  height: var(--wrapper-height);
  margin: 0 0.5rem;
  position: relative;
  overflow: hidden;
}

.wrapper img {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 15;
}

@media (max-width: 1000px) {
  .wrapper {
    width: calc(var(--wrapper-width) * 1.5);
    height: calc(var(--wrapper-height) * 1.5);
  }
}
