.paragraph {
  font-weight: 500;
  margin: 0 0 0.5rem;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.single-control {
  display: flex;
  align-items: center;
  flex: 50%;
  margin-bottom: 1rem;
}

.single-control span {
  font-weight: 600;
}
