.rain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: rain 0.2s linear infinite;
  z-index: 20;
}

@keyframes rain {
  0% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 20% 0%;
  }
}
