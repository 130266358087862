.wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
}

.gc {
  flex: 1;
  margin-right: 2rem;
}

.controls {
  flex: 0 0 calc(30% - 2rem);
}

@media (max-width: 1000px) {
  .wrapper {
    max-width: 90%;
  }

  .row {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 2rem;
  }

  .gc {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .controls {
    flex: 1;
  }
}
