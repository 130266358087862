.Sidebar {
  flex-basis: 40vw;
  background-color: #2d3439;
  padding: 2rem;
  box-sizing: border-box;
  overflow-y: scroll;
}

.Sidebar::-webkit-scrollbar {
  width: 0;
}

@media (max-width: 1000px) {
  .Sidebar {
    height: 50vh;
  }
}
