.Modal {
  position: absolute;
  z-index: 500;
  max-width: 748px;
  height: auto;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  border-radius: 15px;
  transition: all 0.4s ease-out;
  background-color: rgba(24, 24, 24, 0.9);
}

.Header {
  box-sizing: border-box;
  padding: 16px 24px;
}

.Close {
  position: absolute;
  color: white;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
  background-color: transparent;
  font-size: 2.5rem;
  font-weight: 500;
  border: none;
  outline: none;
}

.Title {
  box-sizing: border-box;
  font-size: 19px;
  line-height: 22px;
  font-weight: 600;
  color: white;
}

.Body {
  padding: 0 24px 24px;
}

@media (max-width: 700px) {
  .Modal {
    max-width: 300px;
  }
  .Header {
    padding: 16px 10px;
  }
  .Close {
    right: 5px;
  }
  .Body {
    padding: 0 10px 10px;
  }
}

@media (max-height: 414px) {
  .Modal {
    max-width: 300px;
  }
  .Header {
    padding: 16px 10px;
  }
  .Close {
    right: 5px;
  }
  .Body {
    padding: 0 10px 10px;
  }
}
