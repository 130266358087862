.WaterSpray {
  position: absolute;
  top: calc(10.1vw + 70px);
  left: 19.7vw;
  width: 8.5vw;
  height: 8.5vw;
  margin: auto;
  background: rgba(175, 218, 235, 0.73);
  clip-path: polygon(17% 3%, 22% 1%, 54% 4%, 0% 60%);
  animation: animate 0.8s forwards ease-in-out;
}

@keyframes animate {
  from {
    left: 20.7vw;
    width: 2vw;
    height: 2vw;
  }
  to {
    left: 19.7vw;
    width: 8.5vw;
    height: 8.5vw;
  }
}
