.air-parcel {
  --font-size: 2vw;
  --width: 15vw;
  --height: 3vw;
  --left: 14vw;

  position: absolute;
  width: var(--width);
  height: var(--height);
  border-radius: 50% / 50%;
  background: hsla(255, 100%, 100%, 0);
  left: var(--left);
  bottom: calc(1vw * var(--y_position));
  z-index: 18;
}

.air-parcel::before {
  position: absolute;
  content: attr(temperature);
  color: var(--color);
  font-size: var(--font-size);
  font-weight: 600;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1000px) {
  .air-parcel {
    width: calc(var(--width) * 1.5);
    height: calc(var(--height) * 1.5);
    left: calc(var(--left) * 1.5);
    bottom: calc(1.5vw * var(--y_position));
  }
  .air-parcel::before {
    font-size: calc(var(--font-size) * 1.5);
  }
}
