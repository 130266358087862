.switch {
  position: relative;
  display: block;
  height: 34px;
  margin: 10px 0 10px 10px;
  padding-left: 70px;
  line-height: 34px;
  text-align: center;
  font-weight: 500;
}

.switch input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.SliderRed,
.SliderBlue,
.SliderWhite,
.SliderLightBlue {
  position: absolute;
  cursor: pointer;
  width: 60px;
  height: 34px;
  margin-left: -70px;
  background-color: #ccc;
  transition: 0.4s;
}
.content {
  left: 10px;
}

.SliderRed:before,
.SliderBlue:before,
.SliderWhite:before,
.SliderLightBlue:before {
  box-sizing: border-box;
  position: absolute;
  content: attr(data-content);
  padding-left: 7px;
  padding-top: 3px;
  font-weight: 700;
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.SliderRed:before {
  background-color: var(--color-danger);
  transition: 0.4s;
}

.SliderBlue:before {
  background-color: var(--color-primary);
  transition: 0.4s;
}

input:checked + .SliderRed:before,
input:checked + .SliderBlue:before,
input:checked + .SliderWhite:before,
input:checked + .SliderLightBlue:before {
  transform: translateX(26px);
  background-color: white;
  transition: 0.4s;
}

input:checked + .SliderRed {
  background-color: var(--color-danger);
}

input:checked + .SliderBlue {
  background-color: var(--color-primary);
}

input:checked + .SliderWhite {
  background-color: var(--color-secondary);
}

input:checked + .SliderLightBlue {
  background-color: var(--color-light-blue);
}

.Rounded {
  border-radius: 34px;
}

.Rounded:before {
  border-radius: 50%;
}

.disabled {
  cursor: not-allowed;
}

input:checked:disabled + .SliderRed,
input:checked:disabled + .SliderBlue,
input:checked:disabled + .SliderWhite,
input:checked:disabled + .SliderLightBlue {
  background-color: #ccc;
}
