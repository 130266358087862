.RoadSection {
    position: absolute;
    width: 50px;
    height: 80px;
    top: 75px;
    text-align: center;
    color: white;
    background-color: #444; 
    z-index: 20;
}

.RoadSection:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background:  rgb(22, 22, 22); 
    bottom: -2px;
    transform-origin: top;  
    margin-left: -25px;
}

.RoadSection:before {
    position: absolute;
    top: 30px;
    content: "一";      
}
