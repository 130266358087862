.ProgressBar {
  display: block;
  margin: 20px auto;
}

.FirstCircleBackground {
  fill: none;
}

.SecondCircleBackground {
  fill: none;
}

.ProgressValue {
  font-size: 1.1vw;
  font-family: "Courier New", Courier, monospace;
  text-anchor: middle;
  font-weight: bold;
  fill: #2aab2a;
}

@media (max-width: 1000px) {
  .ProgressValue {
    font-size: 2.2vw;
  }
}
