.Locations ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.Locations li {
  background-color: #42484d;
  border-right: 5px solid #00c46a;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  overflow: hidden;
}

.Icon,
.TrashIcon,
.EditIcon {
  font-size: 1.4rem;
  margin-right: 0.3rem;
}

.TrashIcon,
.EditIcon {
  color: #fff;
  margin-right: 0.5rem;
  cursor: pointer;
}

.LocationName {
  font-size: 1.2rem;
}

.PopulationValue {
  font-size: 1.2rem;
}

.TrashIcon:hover {
  color: #ff4c4c;
}

.EditIcon:hover {
  color: #00c46a;
}

.LocationDetails {
  display: flex;
  flex: 1;
  align-items: baseline;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 1200px) {
  li {
    flex-direction: column;
    gap: 5px;
  }
}

@media (max-width: 1000px) {
  li {
    flex-direction: row;
  }
}
@media (max-width: 568px) {
  li {
    flex-direction: column;
  }
}
