.timeline {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  height: 13vw;
  box-shadow: var(--shadow-light);
  font-size: 1.2vw;
}

.trace {
  display: block;
  width: 100%;
  height: 100%;
}

.timeline::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  transform: translate(-50%, 0);
  background-color: var(--color-danger);
  left: calc(1% * var(--left));
}

.timeline::after {
  content: "";
  position: absolute;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  transform: translate(-50%, 50%);
  background-color: var(--color-danger);
  left: calc(1% * var(--left));
  bottom: calc(1% * var(--bottom));
}

.a {
  position: absolute;
  color: var(--color-danger);
  font-weight: 600;
  left: calc((1% * var(--horizontal)) + 10px);
  bottom: calc((1% * var(--vertical)) + 2px);
}

.x-axis {
  position: absolute;
  width: 100%;
  height: 4px;
  left: 0;
  bottom: 0;
  background-color: var(--color-danger);
}

.y-axis {
  position: absolute;
  width: 4px;
  height: 100%;
  left: 0;
  bottom: 0;
  background-color: var(--color-danger);
}

.x-axis-label {
  position: absolute;
  color: var(--color-danger);
  font-weight: 500;
  bottom: 0.5vw;
  right: 0.5vw;
}

.y-axis-label {
  position: absolute;
  color: var(--color-danger);
  font-weight: 500;
  top: 0.5vw;
  left: 0.7vw;
  white-space: nowrap;
}

.frequency-1,
.frequency-2,
.frequency-3,
.frequency-4,
.frequency-5,
.frequency-6,
.frequency-7 {
  position: absolute;
  color: var(--color-danger);
  font-weight: 600;
  left: 5%;
  bottom: 28%;
}

.frequency-2 {
  left: 19%;
  bottom: 33%;
}

.frequency-3 {
  left: 33%;
  bottom: 41%;
}

.frequency-4 {
  left: 48%;
  bottom: 48%;
}

.frequency-5 {
  left: 62%;
  bottom: 56%;
}

.frequency-6 {
  left: 76%;
  bottom: 63%;
}

.frequency-7 {
  left: 91%;
  bottom: 74%;
}

@media (max-width: 1000px) {
  .timeline {
    font-size: 1.8vw;
  }
}
