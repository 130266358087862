.wrapper {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
}

.header {
  font-weight: 500;
}

.body {
  align-self: center;
  font-size: 1.5rem;
}

.footer {
  align-self: end;
}
