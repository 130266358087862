.Polynomials {
  display: flex;
}

.Polynomial {
  display: flex;
  align-items: center;
}

.Polynomial span {
  font-size: 29px;
  font-weight: bold;
}

.Polynomial:last-child span {
  display: none;
}
