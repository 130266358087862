.Container {
  --fountain-color: rgba(175, 218, 235, 0.73);
  --shadow-color: rgba(162, 216, 237, 0.4);
  --container-width: 60vw;
  --container-height: 40vw;
  --fountain-width: 0.45vw;

  position: relative;
  width: var(--container-width);
  height: var(--container-height);
  display: flex;
  align-items: flex-end;
  margin: 0 0.5rem;
  overflow: hidden;
}

.Fountain {
  position: relative;
  width: var(--fountain-width);
  height: calc(4vw * var(--height));
  background-color: var(--fountain-color);
  box-shadow: 0 0 10px var(--shadow-color), 0 0 20px var(--shadow-color),
    0 0 20px var(--shadow-color), 0 0 20px var(--shadow-color),
    0 0 30px var(--shadow-color), 0 0 30px var(--shadow-color),
    0 0 40px var(--shadow-color), 0 0 40px var(--shadow-color);
  border-radius: 30% 30% 50% 50%;
  margin: 9.8vw 0.42vw;
  z-index: 2;
}

.Fountain::before {
  content: attr(height-data);
  font-weight: bold;
  position: absolute;
  height: 50px;
  width: 70px;
  bottom: -50px;
  border-radius: 10px;
  left: 0;
  display: none;
  background: rgba(241, 241, 241, 0.9);
}

.Fountain:hover::before {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Fountain:first-child {
  margin-left: 6.7vw;
}

.Fountain:nth-child(7n) {
  margin-right: 4.12vw;
}

.Img {
  position: absolute;
  width: var(--container-width);
  height: var(--container-height);
  top: 0;
  left: 0;
}

@media (max-width: 1000px) {
  .Container {
    width: calc(var(--container-width) * 1.5);
    height: calc(var(--container-height) * 1.5);
  }

  .Fountain {
    margin: 14.5vw 0.63vw;
    width: calc(var(--fountain-width) * 1.5);
    height: calc(6vw * var(--height));
  }

  .Fountain:first-child {
    margin-left: 10.05vw;
  }

  .Fountain:nth-child(7n) {
    margin-right: 6.18vw;
  }

  .Img {
    width: calc(var(--container-width) * 1.5);
    height: calc(var(--container-height) * 1.5);
  }
}
