.wrapper {
  width: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column {
  flex-direction: column-reverse;
  width: auto;
  height: 100px;
}

.wrapper button {
  cursor: pointer;
  border: none;
  color: #fff;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
  transition: filter 0.2s;
}

.wrapper button:hover {
  filter: brightness(95%);
}

.plus {
  background-color: var(--color-success);
}

.minus {
  background-color: var(--color-danger);
}

.wrapper input {
  text-align: center;
  font-size: 1.05rem;
  font-weight: 600;
  padding: 0.3rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
