.WaterTank {
  position: relative;
  width: 15vw;
  height: 20vw;
  border-left: 3px solid #999999;
  border-right: 3px solid #999999;
  border-bottom: 3px solid #999999;
  border-radius: 0 0 1vw 1vw;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WaterTank::before {
  content: "";
  position: absolute;
  width: 15vw;
  height: calc(0.034vw * var(--waterVolume));
  left: 0;
  bottom: 0;
  background-color: #afdaeb;
  border-radius: 0 0 calc(1vw - 3px) calc(1vw - 3px);
  z-index: -1;
  transition: height 0.5s ease-out 0.2s;
}
