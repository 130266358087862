.ExBuilder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Week {
  display: flex;
  align-items: center;
}

.Week p {
  min-width: 100px;
  font-weight: 600;
  margin: 0;
}

.Buttons {
  display: flex;
  margin-top: 20px;
}

.SignButtons {
  position: relative;
}

.SignButtons::before {
  position: absolute;
  content: attr(hint);
  padding: 7px;
  height: auto;
  width: auto;
  white-space: nowrap;
  background: #808080;
  color: white;
  display: none;
  font-size: 0.75rem;
}

.SignButtons:hover::before {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -30px;
}
