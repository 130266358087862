.wrapper {
  --deltaX: 100vw;

  position: absolute;
  width: calc(1vw * var(--width));
  height: calc(1vw * var(--height));
  left: calc(1vw * var(--left));
  top: calc(1vw * var(--top));
  transform: translateX(calc(var(--deltaX) * -1));
  z-index: 20;
}

.wrapper img:first-child {
  position: absolute;
  width: 100%;
  /* height: calc(100% + var(--mascot-shadow-angle) * 0.9%); */
  height: calc(100% - var(--height-shift) * 1%);
  bottom: 0;
  transform-origin: bottom center;
  filter: drop-shadow(var(--deltaX) 0 1px rgba(0, 0, 0, 0.6));
  transform: skewX(calc(1deg * var(--mascot-shadow-angle)));
}

.wrapper img:last-child {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  transform: translateX(var(--deltaX));
}

.wrapper div {
  position: absolute;
  width: 100%;
  height: calc(var(--shadow-height) * 1%);
  transform-origin: bottom right;
  box-shadow: 4px 0 0 rgba(0, 0, 0, 0.6);
  transform: translateX(var(--deltaX))
    rotate(calc(var(--rotation-angle) * 1deg));
  right: calc(var(--shadow-right) * 1%);
  bottom: 5%;
}

@media (max-width: 1000px) {
  .wrapper {
    width: calc(1.5vw * var(--width));
    height: calc(1.5vw * var(--height));
    left: calc(1.5vw * var(--left));
    top: calc(1.5vw * var(--top));
  }
}
