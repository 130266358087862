.temperature-1,
.temperature-2,
.temperature-3 {
  --t-font-size: 1.3vw;
  --t-left-1: 10vw;
  --t-left-2: 30vw;
  --t-left-3: 50vw;
  --t-top: 4vw;

  position: absolute;
  left: var(--t-left-1);
  top: var(--t-top);
  font-size: var(--t-font-size);
  font-weight: 700;
  color: hsl(270, 100%, 30%);
  z-index: 20;
}

.temperature-2 {
  left: var(--t-left-2);
}

.temperature-3 {
  left: var(--t-left-3);
}

@media (max-width: 1000px) {
  .temperature-1,
  .temperature-2,
  .temperature-3 {
    font-size: calc(var(--t-font-size) * 1.5);
    top: calc(var(--t-top) * 1.5);
    left: calc(var(--t-left-1) * 1.5);
  }

  .temperature-2 {
    left: calc(var(--t-left-2) * 1.5);
  }

  .temperature-3 {
    left: calc(var(--t-left-3) * 1.5);
  }
}
