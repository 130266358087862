.SNA11 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 1000px) {
  .SNA11 {
    flex-direction: column;
    align-items: center;
  }
}
