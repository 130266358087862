.sky {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color);
  z-index: 10;
  transition-property: background;
  transition-duration: 2s;
}
