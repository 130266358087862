.wrapper {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: var(--shadow-light);
}

.wrapper button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper button svg {
  margin-right: 8px;
}

.info {
  background-color: rgb(38, 38, 38);
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.time-display {
  color: white;
  line-height: 1.5rem;
  flex: 0 0 35%;
  overflow: hidden;
  text-align: right;
}

.time-separator {
  margin: 0 0.1rem;
}

.volume-control,
.speed-control {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.volume-control input,
.speed-control input {
  width: 100%;
}

.volume-control span,
.speed-control span {
  display: flex;
  align-items: center;
}

.volume-control svg,
.speed-control svg {
  color: white;
  font-size: 1.3rem;
}

.speed-control svg {
  font-size: 1.2rem;
}

@media (max-width: 1000px) {
  .info {
    gap: 2rem;
  }

  .radio-buttons {
    display: flex;
    justify-content: space-around;
  }
}
