.SettingsForm {
  background-color: #42484d;
  border-right: 5px solid #ff4c4c;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.Inputs {
  display: flex;
  flex-direction: column;
}

.EditIcon {
  color: #fff;
  cursor: pointer;
  margin: 14.5px auto;
  display: block;
}

.EditIcon:hover {
  color: #00c46a;
}
