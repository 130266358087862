.time {
  padding: 1.5rem;
  box-shadow: var(--shadow-light);
  text-align: center;
  background-color: #fff;
}

.time p {
  line-height: 1.7rem;
}

.time p strong {
  font-size: 1.1rem;
  display: inline-block;
  padding: 0 0.3rem;
  animation: pulsate 1.3s infinite var(--play-state);
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow: none;
  }

  50% {
    transform: scale(1.03);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.25);
  }

  100% {
    transform: scale(1);
    box-shadow: none;
  }
}
