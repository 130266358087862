.SNA2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-left: calc(100vw - 100%);
}

.KneeContainer {
  position: relative;
  width: 40vw;
  height: 40vw;
}

@media (max-width: 1000px) {
  .SNA2 {
    flex-direction: column;
    align-items: center;
  }
  .KneeContainer {
    width: 90vw;
    height: 90vw;
  }
}
