.SmartWatch {
  width: 100%;
  height: 100%;
}

.SmartWatchScale {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
}
