.Container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
}

.PlusButton,
.MinusButton {
  position: relative;
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
}

.PlusButton:disabled,
.MinusButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.PlusButton:before,
.PlusButton:after,
.MinusButton:before,
.MinusButton:after {
  content: "";
  position: absolute;
  background: #fff;
}

.PlusButton:before {
  left: 50%;
  top: 8px;
  bottom: 8px;
  width: 4px;
  transform: translateX(-50%);
}

.PlusButton:after,
.MinusButton:after {
  top: 50%;
  left: 8px;
  right: 8px;
  height: 4px;
  transform: translateY(-50%);
}

.Success {
  background-color: rgba(63, 194, 134);
}

.Success:hover:enabled {
  background-color: rgb(59, 177, 124);
}

.Danger {
  background-color: rgb(230, 70, 70);
}

.Danger:hover:enabled {
  background-color: rgb(213, 57, 57);
}

.Value {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  width: 40px;
  height: 30px;
  font-size: 22px;
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 5px;
}
