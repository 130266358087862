.Belt {
  position: relative;
  width: 140vw;
  height: 15vw;
  background: #525252;
  margin-top: 6vw;
  margin-left: -20vw;
  transform-origin: bottom;
  transform-style: preserve-3d;
  transform: perspective(700px) rotateX(50deg);
}

.Belt::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 13vw;
  top: 1vw;
  left: 0;
  background: linear-gradient(
    90deg,
    #504f4f 0%,
    #504f4f 80%,
    #525252 80%,
    #525252 100%
  );
  background-size: 13vw;
  background-position: calc(1vw * var(--conveyorBeltPosition));
  z-index: -1;
  /* animation: animate 0.9s linear infinite; */
}

/* .Belt::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 10px;
  background: #333;
  bottom: -10px;
  transform-origin: top;
  transform: perspective(700px) rotateX(-30deg);
} */

.BeltBottom {
  width: 140vw;
  height: 10px;
  background: #333;
}

.WaterPipe {
  position: absolute;
  width: 25vw;
  height: 10.9vw;
  z-index: 1;
  left: 0vw;
  top: calc(0vw + 70px);
}

@keyframes animate {
  0% {
    background-position: 0px;
  }
  100% {
    background-position: -13vw;
  }
}
