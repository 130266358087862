.Container {
  position: absolute;
  left: 0;
  top: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 220px;
  border: 1px solid #333333;
  border-radius: 10px;
}

.Display {
  position: relative;
  grid-column: 1 / span 2;
  height: 4.5rem;
  border-radius: 9px 9px 0 0;
  background: #333333;
  color: white;
  font-size: 3.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0rem 0.5rem;
}

.CloseButton {
  position: absolute;
  top: 1px;
  right: 1px;
  background: #333333;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: white;
  cursor: pointer;
  border: 1px solid white;
  outline: none;
}

.Icon {
  width: 20px;
  height: 20px;
}

.DisplayIcon {
  width: 3rem;
  height: 3rem;
}
