.wrapper {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.header {
  padding: 0.75rem 1rem;
  background-color: rgba(0, 0, 0, 0.05);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.btn {
  background-color: transparent;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn svg {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.content-visible svg {
  transform: rotate(90deg);
}

.header h4 {
  margin: 0;
}

.content {
  padding: 1rem;
}
