.Map {
  position: relative;
  flex-basis: calc(60vw - 40px);
  height: calc(100vh - 90px);
  z-index: 90;
}

@media (max-width: 1000px) {
  .Map {
    height: calc(50vh - 90px);
  }
}
