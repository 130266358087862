.wrapper {
  --base-width: 15vw;
  --base-height: 4.2vw;
  --base-border-radius: 2.5vw;
  --circle-1-size: 6vw;
  --circle-2-size: 7.5vw;
  --circle-1-left: 2vw;
  --circle-2-left: 5vw;
  --bar-width: 15vw;
  --bar-height: 10vw;

  position: absolute;
  width: 10vw;
  height: 7.5vw;
  top: 2.5vw;
  left: calc(1vw * var(--x_position));
  transition: left linear 2s;
}

.base {
  position: absolute;
  width: calc(var(--base-width) * var(--coeficient));
  height: calc(var(--base-height) * var(--coeficient));
  background: white;
  border-radius: var(--base-border-radius);
  bottom: 0;
  z-index: 17;
  overflow: hidden;
  transition: all linear 2s;
  transition-property: width, height;
}

.circle-1 {
  position: absolute;
  width: calc(var(--circle-1-size) * var(--coeficient));
  height: calc(var(--circle-1-size) * var(--coeficient));
  content: "";
  bottom: 0;
  left: calc(var(--circle-1-left) * var(--coeficient));
  background: white;
  border-radius: 50%;
  z-index: 16;
  overflow: hidden;
  transition: all linear 2s;
  transition-property: width, height, left;
}

.circle-2 {
  position: absolute;
  width: calc(var(--circle-2-size) * var(--coeficient));
  height: calc(var(--circle-2-size) * var(--coeficient));
  content: "";
  bottom: 0;
  left: calc(var(--circle-2-left) * var(--coeficient));
  background: white;
  border-radius: 50%;
  z-index: 15;
  overflow: hidden;
  transition: all linear 2s;
  transition-property: width, height, left;
}

.bar {
  position: absolute;
  width: calc(var(--bar-width) * 1.5);
  height: calc(var(--bar-height) * var(--coeficient));
  background-color: var(--color);
  bottom: 0;
  transition-property: background;
  transition-duration: 2s;
}

@media (max-width: 1000px) {
  .wrapper {
    top: 1vw;
    height: calc(var(--circle-2-size) * 1.5 * var(--coeficient));
    left: calc(1.5vw * var(--x_position));
  }

  .base {
    width: calc(var(--base-width) * 1.5 * var(--coeficient));
    height: calc(var(--base-height) * 1.5 * var(--coeficient));
    border-radius: calc(var(--base-border-radius) * 1.5);
  }

  .circle-1 {
    width: calc(var(--circle-1-size) * 1.5 * var(--coeficient));
    height: calc(var(--circle-1-size) * 1.5 * var(--coeficient));
    left: calc(var(--circle-1-left) * 1.5 * var(--coeficient));
  }

  .circle-2 {
    width: calc(var(--circle-2-size) * 1.5 * var(--coeficient));
    height: calc(var(--circle-2-size) * 1.5 * var(--coeficient));
    left: calc(var(--circle-2-left) * 1.5 * var(--coeficient));
  }

  .bar {
    width: calc(var(--bar-width) * 1.5 * var(--coeficient));
    height: calc(var(--bar-height) * 1.5 * var(--coeficient));
  }
}
