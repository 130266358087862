.wrapper {
  max-width: 1200px;
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
}

.content {
  flex: 1;
  margin-right: 2rem;
  padding: 1rem;
  box-shadow: var(--shadow-light);
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.controls {
  flex: 1;
}

@media (max-width: 1000px) {
  .wrapper {
    flex-direction: column;
    max-width: 90%;
    align-items: stretch;
    margin-bottom: 2rem;
  }

  .content {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
