.sun {
  --sun-size: 5vw;
  --sun-top: 2vw;
  --sun-left: -6vw;
  --sun-rays-width: 8vw;
  --sun-rays-height: 0.5vw;

  /* For sun rays animation */
  --sun-rays-max-width: 12vw;
  --sun-rays-max-height: 0.2vw;

  position: absolute;
  width: var(--sun-size);
  height: var(--sun-size);
  padding: 0;
  border-radius: 50%;
  top: var(--sun-top);
  left: calc(1vw * var(--x_position));
  background: var(--color);
  z-index: 12;
  transition: left linear 2s;
}

.sun li {
  position: absolute;
  list-style: none;
  width: var(--sun-rays-width);
  height: var(--sun-rays-height);
  border-radius: 0.25vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--color);
  animation: sun60 0.5s linear infinite;
  animation-play-state: var(--status);
}

.sun li:nth-child(2) {
  transform: translate(-50%, -50%) rotate(45deg);
}

.sun li:nth-child(3) {
  transform: translate(-50%, -50%) rotate(90deg);
}

.sun li:nth-child(4) {
  transform: translate(-50%, -50%) rotate(135deg);
}

@media (max-width: 1000px) {
  .sun {
    width: calc(var(--sun-size) * 1.5);
    height: calc(var(--sun-size) * 1.5);
    top: calc(var(--sun-top) * 1.5);
    left: calc(1.5vw * var(--x_position));
  }

  .sun li {
    width: calc(var(--sun-rays-width) * 1.5);
    height: calc(var(--sun-rays-height) * 1.5);
    animation: sun90 0.5s linear infinite;
  }
}

/*  Sun rays animation: 60 & 90 main wrapper width (vw)*/
@keyframes sun60 {
  100% {
    width: var(--sun-rays-max-width);
    height: var(--sun-rays-max-height);
  }
}

@keyframes sun90 {
  100% {
    width: calc(var(--sun-rays-max-width) * 1.5);
    height: calc(var(--sun-rays-max-height) * 1.5);
  }
}
