.language-switcher {
  position: relative;
  width: 150px;
  font-weight: bold;
}

.selected-language {
  cursor: pointer;
  color: white;
  font-size: inherit;
  font-weight: bold;
  background: none;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.selected-language svg {
  font-size: 1.3rem;
  padding: 10px;
}

.language-switcher .caret {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.language-switcher:hover .caret {
  transform: rotate(180deg);
}

.language-switcher ul {
  position: absolute;
  top: 40px;
  right: 0;
  margin: 0;
  padding: 10px;
  min-width: 120px;
  background-color: white;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  opacity: 0;
  transform: translateY(-15px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.language-switcher ul li {
  list-style: none;
  padding: 10px;
  cursor: pointer;
}

.language-switcher ul li a {
  text-decoration: none;
  color: black;
}

.language-switcher ul li:hover {
  background-color: #f2f2f2;
}

.language-switcher:hover ul {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}
