.wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 1000px) {
  .wrapper {
    flex-wrap: wrap;
  }
}
