.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.wrapper h1 {
  font-size: 3rem;
  margin: 0;
}

.wrapper a {
  text-decoration: none;
}

.wrapper button {
  margin: 0 auto;
}
