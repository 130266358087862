.wrapper {
  align-self: center;
  margin-bottom: 1rem;
  max-width: calc(600px - 3rem);
}

.label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.3rem;
}

.label-wrapper button {
  border: none;
  cursor: pointer;
  background-color: var(--color-danger);
  color: #fff;
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
}

.label {
  font-weight: 500;
}

.editable-field {
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem;
}

.error {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--color-danger);
}

@media (max-width: 1000px) {
  .wrapper {
    max-width: calc(900px - 3rem);
  }
}
