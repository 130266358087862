.CoordinatePlane {
    width: 65%;
    margin: auto;
    position: relative;
    display: flex;
    gap: 1%;
    overflow: hidden;
}

@media (max-width: 1000px) {
    .CoordinatePlane {
        width: 75%;
        flex-wrap: wrap;  
        justify-content: center;  
        gap: 10px;     
    }  
}