.wrapper {
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
}

.wrapper img {
  height: 100%;
  width: 100%;
}
