.FormBuilder {
  flex-basis: 39%;
  padding: 15px;
  height: 40vw;
  overflow: auto;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
}

.Btn {
  display: flex;
  justify-content: center;
}

@media (max-width: 1000px) {
  .FormBuilder {
    flex-basis: 73vw;
    height: 65vw;
  }
}
