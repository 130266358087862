.RoadContainer {
  position: relative;
  display: flex;
  width: 1300px;
  margin: auto;
  overflow: hidden;
}

.RoadContainer img {
  position: absolute;
  left: 0;
  top: 158px;
  z-index: 100;
  max-width: 1300px;
}

.ControlsContainer {
  display: flex;
  max-width: 1300px;
  margin: 10px auto;
}

@media (max-width: 768px) {
  .ControlsContainer {
    flex-direction: column;
    margin: 1rem auto;
    height: 880px;
  }
}
