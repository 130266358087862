.increment-controls {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.increment-control {
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-right: 1px solid #ccc;
}

.increment-control:last-child {
  border-right: none;
}

.unit {
  font-size: 1.2rem;
  font-weight: bold;
}

.variable-type {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  font-size: 1.6rem;
  font-weight: bold;
}
