.step-with-img {
  padding: 1rem 0;
  border-bottom: 1px solid #ccc;
  line-height: 1.3rem;
}

.step-inner {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.step {
  display: flex;
  align-items: flex-start;
  padding: 1rem 0;
  gap: 0.5rem;
  line-height: 1.3rem;
}

.step:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.numeration {
  margin-right: 1rem;
  font-weight: 600;
  font-size: 1.1rem;
}

.paragraph {
  margin: 0;
}

.expression {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  font-weight: bold;
  font-size: 1.3rem;
  letter-spacing: 1px;
}

.show-list-img {
  width: 22px;
  height: auto;
}

.expression-img {
  width: 140px;
  height: auto;
  display: block;
  margin: 0 auto;
}
