.theater {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.theater img {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 50;
}
