.keypad-btn {
  cursor: pointer;
  color: black;
  font-size: 1.2rem;
  background-color: #ddd;
  border: 1px solid #aaa;
  outline: none;
}

.exponent {
  font-size: 0.8rem;
  display: inline-block;
  transform: translateY(-0.5rem);
}

.keypad-btn:hover {
  background-color: #bbb;
}

@media (max-width: 600px) {
  .keypad-btn {
    font-size: 0.7rem;
  }

  .exponent {
    font-size: 0.5rem;
  }
}
