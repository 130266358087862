.card-link {
  color: black;
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  border-radius: 25px;
  overflow: hidden;
  text-decoration: none;
  transition: transform 0.25s ease-out;
}

.card-link:hover {
  transform: scale(1.03);
  transition: transform 0.25s ease-out;
}

.card-link img {
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.card-info {
  padding: 5px 10px;
  text-align: center;
}

.card-info h4 {
  text-align: center;
}

.card-info p:after {
  content: "";
  display: block;
  width: 60px;
  height: 2px;
  margin: 10px auto;
  background-color: red;
}
