.radio {
  display: block;
  margin-bottom: 1.4rem;
  cursor: pointer;
  position: relative;
}

.radio-input {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.radio-label {
  padding-left: 2rem;
}

.radio-button {
  width: 1rem;
  height: 1rem;
  border: 3px solid var(--color-primary);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: -0.1rem;
}

.radio-button::after {
  content: "";
  display: block;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background-color: var(--color-primary);
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s;
}

.radio-input:checked + .radio-button::after {
  opacity: 1;
}

.radio-input:enabled:hover + .radio-button {
  outline: 0.5rem solid rgba(56, 98, 224, 0.1);
}

.radio-input:disabled + .radio-button {
  border: 2px solid #ccc;
}

.radio-input:checked:disabled + .radio-button::after {
  background-color: #ccc;
}
