.circle-1,
.circle-2,
.circle-3,
.circle-4,
.circle-5,
.circle-6,
.circle-7,
.circle-8,
.circle-9,
.circle-10 {
  position: absolute;
  width: calc(1vw * var(--size));
  height: calc(1vw * var(--size));
  border-radius: 50%;
  bottom: calc(1vw * var(--y_position));
  left: calc(1vw * var(--x_position) - (1vw * var(--size) / 2));
  background: hsla(255, 100%, 100%);
  opacity: var(--opacity);
  z-index: 18;
}

@media (max-width: 1000px) {
  .circle-1,
  .circle-2,
  .circle-3,
  .circle-4,
  .circle-5,
  .circle-6,
  .circle-7,
  .circle-8,
  .circle-9,
  .circle-10 {
    width: calc(1.5vw * var(--size));
    height: calc(1.5vw * var(--size));
    bottom: calc(1.5vw * var(--y_position));
    left: calc(1.5vw * var(--x_position) - (1.5vw * var(--size) / 2));
  }
}
