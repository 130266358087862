.SNA6,
.BrakeSystemNotification,
.StatusNotification {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.Info {
  width: 40vw;
  margin: 0.5rem;
  border: 1px solid #aaa;
  border-radius: 20px;
}

.Info p {
  font-weight: 500;
  margin: 1em;
}

.Info div {
  display: flex;
}

.StatusNotification {
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #aaa;
}

.StatusNotification p {
  width: calc(40% - 2rem);
}

.BrakeSystemNotification {
  align-items: center;
  padding: 0.6rem 1rem;
  margin: 1em;
  width: calc(60% - 2rem);
  color: white;
  font-weight: bold;
  background-color: #eead14;
  border-radius: 10px;
}

.Success,
.Fail {
  color: #008000;
  font-weight: bold;
}

.Fail {
  color: #ff1919;
  animation: blinker 1s ease-in infinite;
}

@media (max-width: 1023px) {
  .SNA6 {
    flex-direction: column;
    align-items: center;
  }
  .Info {
    width: 85vw;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
