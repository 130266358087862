.LocationForm {
  background-color: #42484d;
  border-radius: 5px;
  padding: 1rem;
}

.Inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  width: 550px;
}

@media (max-width: 700px) {
  .Inputs {
    display: block;
    width: auto;
  }
}
