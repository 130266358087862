:root {
  --color-primary: rgb(56, 98, 224);
  --color-secondary: rgb(133, 133, 133);
  --color-success: rgb(63, 194, 134);
  --color-danger: rgb(230, 70, 70);
  --color-warning: rgb(238, 173, 20);
  --color-light-blue: rgb(42, 153, 240);

  --color-grey-1: #102a42;
  --color-red-light: #ffcccc;

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.2);
  --shadow-light: 0 2rem 4rem rgba(0, 0, 0, 0.06);
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* full height - header height + margin */
.page-100 {
  min-height: calc(100vh - 70px);
}

/* full height - header height - footer height + margins */
.page {
  min-height: calc(100vh - (90px + 6rem));
}

@media (max-width: 375px) {
  .page {
    min-height: calc(100vh - (90px + 9rem));
  }
}
