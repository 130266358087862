.input {
  width: 100%;
  margin-bottom: 1rem;
  box-sizing: border-box;
  display: inline-block;
}

.label {
  color: var(--color-grey-1);
  font-weight: 600;
  display: block;
  text-align: left;
  margin-bottom: 0.2rem;
}

.input-element {
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 3px;
  font: inherit;
  padding: 0.5rem 0.8rem;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.invalid {
  background-color: var(--color-red-light);
  border: 1px solid var(--color-danger);
}

.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.validation-error {
  color: var(--color-danger);
  position: relative;
  text-align: left;
  font-size: 0.8rem;
  font-weight: 700;
  margin: 0.1rem 0;
}
