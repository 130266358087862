.lightning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 20;
  animation: lightining 4s linear 5s infinite;
  opacity: 0;
}

.lightning::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  animation: lightining-background 4s linear 5s infinite;
  opacity: 0;
}

@keyframes lightining {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  21% {
    opacity: 1;
  }
  28% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  31% {
    opacity: 1;
  }
  38% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes lightining-background {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  21% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  31% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
