.sidedrawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 640px;
  height: 100%;
  z-index: 1001;
  transition: transform 0.2s ease-out;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: #eee;
  z-index: 100;
  border-left: 5px solid var(--color-primary);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: auto;
}

.content {
  padding: 0 2.5rem;
}

.content h2 {
  text-align: center;
}
.marker {
  position: absolute;
  background-color: var(--color-primary);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: 50%;
  transform: translate(-35%, -50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.marker:hover {
  background-color: rgb(31, 67, 177);
}

.marker svg {
  position: absolute;
  font-size: 2.5rem;
  color: #fff;
  transform: translateX(-60%);
}

.close-btn {
  width: 2.3rem;
  height: 2.3rem;
  font-size: 2.1rem;
  line-height: 2.1rem;
  text-align: center;
  color: black;
  background: transparent;
  border-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.close-btn:hover {
  background-color: #ddd;
}

.open {
  transform: translateX(0);
}

.close {
  transform: translateX(100%);
}

.marker-visible {
  opacity: 1;
}

.marker-non-visible {
  opacity: 0;
  transition: opacity 0.3s linear;
}

@media (max-width: 768px) {
  .sidedrawer {
    width: 100%;
  }
}
