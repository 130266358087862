.skeleton-wrapper {
  border-radius: 25px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  overflow: hidden;
  animation: skeleton 0.5s ease infinite alternate;
}

.skeleton-img {
  background-color: #ccc;
  width: 100%;
  height: 200px;
}

.skeleton-info {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.skeleton-title {
  background-color: #ccc;
  width: 85%;
  height: 25px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.skeleton-text {
  background-color: #ccc;
  width: 55%;
  height: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

@keyframes skeleton {
  to {
    opacity: 0.5;
  }
}
