.wrapper {
  position: relative;
  width: 22vw;
  height: 30vw;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.grinder {
  position: absolute;
  width: 15vw;
  height: 22.32vw;
}

.wheel {
  position: absolute;
  width: 14.3vw;
  height: 14.3vw;
  animation: rotate var(--duration-wheel) infinite linear var(--play-state);
}

.grain {
  position: absolute;
  background-size: cover;
  top: 0;
  width: 8vw;
  height: 8.6vw;
  animation: grain var(--duration-grain) infinite linear var(--play-state);
}

.flour {
  position: absolute;
  width: 4vw;
  height: 8vw;
  bottom: 0;
  background-size: cover;
  animation: grain var(--duration-flour) infinite linear var(--play-state);
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes grain {
  0% {
    background-position: 30% 94%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@media (max-width: 1000px) {
  .wrapper {
    width: 90vw;
    height: 54vw;
    margin-bottom: 1rem;
    order: -1;
  }

  .grinder {
    width: 30vw;
    height: 46.6vw;
  }

  .wheel {
    width: 29.4vw;
    height: 29.4vw;
  }

  .grain {
    width: 16vw;
    height: 17.2vw;
    top: -3.5vw;
  }

  .flour {
    width: 8vw;
    height: 14.4vw;
  }
}
