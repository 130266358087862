.AnimatedButton {
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  font: inherit;
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
  display: block;
  letter-spacing: 0.05em;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.AnimatedButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.Danger {
  background-color: rgb(230, 70, 70);
}

.Danger:hover:not(:disabled) {
  background-color: rgb(205, 45, 45);
}

.AnimatedButton:not(:disabled) {
  animation: enable 0.4s linear;
}

@keyframes enable {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
