.SNA15 {
  overflow-x: hidden;
}

.Controls {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px auto;
  flex-wrap: wrap;
}

.InputPanels {
  position: relative;
  width: 220px;
  height: 310px;
}
