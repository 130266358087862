.SNA3 {
    width: 65%;
    margin: auto;
    position: relative;
    display: flex;
    gap: 1%;
    overflow: hidden;
}

.btn {
    display: flex;
    justify-content: center;
    gap: 1%;
}

@media (max-width: 1000px) {
    .SNA3 {
        width: 75%;
        flex-wrap: wrap;  
        justify-content: center;  
        gap: 10px;   
    }  
}
