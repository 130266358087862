.Container {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.Variable {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  font-size: 2rem;
  font-weight: bold;
  overflow: hidden;
}

.Variable[disabled] {
  background-color: #ddd;
  pointer-events: none;
}

.Img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Variable:hover {
  background: #eee;
}
