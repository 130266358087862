.Button {
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  font: inherit;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 18px;
  display: block;
  letter-spacing: 0.04em;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.Button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.Success {
  background-color: rgb(63, 194, 134);
}

.Danger {
  background-color: rgb(230, 70, 70);
}

.Warning {
  background-color: rgb(238, 173, 20);
}

.Primary {
  background-color: rgb(56, 98, 224);
}

.Secondary {
  background-color: rgb(133, 133, 133);
}

.Dark {
  background-color: rgb(52, 58, 63);
}

.Info {
  background-color: rgb(33, 148, 165);
}

.Success:hover:enabled {
  background-color: rgb(59, 177, 124);
}

.Danger:hover:enabled {
  background-color: rgb(213, 57, 57);
}

.Warning:hover:enabled {
  background-color: rgb(235, 146, 43);
}

.Primary:hover:enabled {
  background-color: rgb(31, 67, 177);
}

.Secondary:hover:enabled {
  background-color: rgb(109, 109, 109);
}

.Dark:hover:enabled {
  background-color: rgb(34, 48, 42);
}

.Info:hover:enabled {
  background-color: rgb(27, 132, 148);
}
