.SNA1 {
  position: relative;
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  grid-gap: 20px;
}

.Controls {
  width: 80%;
  margin: auto;
  display: grid; /* grid for gap in Safari */
  grid-template-columns: 2fr 1fr 1fr;
  margin-top: 8px;
  justify-content: space-around;
  gap: 10px;
  margin-bottom: 20px;
}

.StartReset {
  display: flex;
}

.StartReset button:first-child {
  width: 50%;
  margin-right: 5px;
}

.StartReset button:last-child {
  width: 50%;
  margin-left: 5px;
}

.Dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.Form {
  display: flex;
  padding: 15px;
  flex: 1;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.HelpVideos {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.VideoButtons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.HelpVideos:after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  margin: 20px auto;
  background-color: red;
}

.Icon {
  margin: 5px 0 0 5px;
}

.Youtube {
  width: 700px;
  height: 394px;
}

@media (max-width: 1000px) {
  .SNA1 {
    grid-template-columns: 1fr;
  }
  .Controls {
    width: 90%;
    grid-template-columns: 1fr;
  }
}

@media (max-width: 700px) {
  .Youtube {
    width: 280px;
    height: 158px;
  }
}

@media (max-width: 414px) {
  .VideoButtons {
    margin-bottom: 20px;
  }
  .Dashboard {
    grid-template-columns: 1fr;
  }
}

@media (max-height: 414px) {
  .Youtube {
    width: 280px;
    height: 158px;
  }
}
